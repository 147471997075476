import AxiosInstance from "../../../common/utils/axiosConfig";

// Simple generator to track the state of pagination without messing with the redux store
async function* fetchSmsTemplates(url: string = "/sms-templates/?page_size=50") {
  let nextPage = url;
  while (nextPage) {
    const {
      data: { results, next },
    } = await AxiosInstance.get(nextPage);

    yield results;

    nextPage = next;
  }
}

export const fetchSmsTemplatesGenerator = fetchSmsTemplates();

export const getSmsTemplatesWithFilter = (categoryId: number, pageSize: number) => {
  return AxiosInstance.get(
    `/sms-templates/?page_size=${pageSize}&category=${categoryId}`
  );
};

export const updateSmsTemplate = (id: number, payload: any) => {
  return AxiosInstance.patch(`/sms-templates/${id}/`, payload);
};

export const createSmsTemplate = (payload: any) => {
  return AxiosInstance.post("/sms-templates/", payload);
};

export const postUpload = async (data: FormData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await AxiosInstance.post("/sms-templates/upload/", data, config);
  return response.data;
};
