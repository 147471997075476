import axios from "axios";
import ReduxStore from "../../main/store/store";
import { loadTokens } from "../../main/store/Auth/loadTokens";
import { getNewAccessToken } from "../../main/store/Auth/utils";
import { addNewToast } from "../../main/store/Global/Toasts/actions";
import { setServerStatus } from "../../main/store/Global/actions";
import { generalNetworkError } from "module/common/helpers/variables";
import { logout } from "../../main/store/Auth/thunks";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({ baseURL });

// adds interceptor for 401's & server down
const okResponseInterceptor = (response: any) => {
  ReduxStore.dispatch(setServerStatus("ok"));
  return response;
};
const errorResponseInterceptor = (error: any) => {
  if (error.response) {
    const { status } = error.response;
    const {
      auth: { refreshToken },
    } = loadTokens();
    if (error.response.data?.code === "token_not_valid") {
      // if we get an invalid-refresh token then logout
      ReduxStore.dispatch(logout());
      return Promise.reject(error);
    }

    // updates access token if is an error 401 AND refresh token is valid
    if (status === 401 && refreshToken) {
      return getNewAccessToken(refreshToken, error);
    }
    if (status === 403) {
      ReduxStore.dispatch(logout());
    }
    // display error toast/alert if error code higher than the ones handled from backend
    if (status > 405) {
      // NOTE: Add logs errors from the server via sentry/trackjs or any other services
      ReduxStore.dispatch(addNewToast({ message: generalNetworkError, color: "danger" }));
      return Promise.reject(error.response || {});
    }
  } else if (error.request) {
    ReduxStore.dispatch(setServerStatus("bad"));
  } else {
    // Something happened in setting up the request that triggered an Error
    // TODO: show an error here?
  }

  return Promise.reject(error);
};

// handle case where we get =401= unauthorized
axiosInstance.interceptors.response.use(okResponseInterceptor, errorResponseInterceptor);

export const delayedRequest = (expr: any, timeout: number) => {
  return expr.then((response: any) => {
    return new Promise((resolve) => setTimeout(resolve, timeout, response));
  });
};

export default axiosInstance;
